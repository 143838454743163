/* src/components/common/Header/Header.css */

.header {
    background-color: transparent !important;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 10px 20px !important;
    box-shadow: none !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 10 !important;
}

.header-scrolled {
    background-color: white !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.logo {
    height: 80px !important;
    margin: 0 1% !important;
}

.logo-hidden {
    display: none !important;
}

.dashboard-text {
    color: var(--dark-purple) !important;
    font-size: 1.3rem !important;
}

.toolbar {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 !important;
}

.left-section {
    display: flex !important;
    align-items: center !important;
    gap: 1rem !important;
    width: 80% !important;
}

.nav-links {
    display: flex !important;
    gap: 1rem !important;
    align-items: center !important;
}

.nav-links a, .nav-links button {
    color: var(--light-gray) !important;
    text-decoration: none !important;
    font-weight: 500 !important;
    transition: color 0.3s ease;
    font-family: var(--font-title) !important;
    height: 45px !important; 
    line-height: 45px !important; /* Align text vertically */
    padding: 0 5px !important; /* Horizontal padding */
    white-space: nowrap !important; /* Prevent wrapping */
    display: flex !important; /* Flex for vertical alignment */
    align-items: center !important; /* Center text vertically */
    justify-content: center !important; /* Center text horizontally */
    border-radius: 6px !important; /* Rounded corners for better appearance */
    border: none !important; /* Remove button border */
    background: none !important; /* Transparent background */
    cursor: pointer !important;
    font-size: .9rem !important;
}

.nav-links button:hover {
    color: var(--primary-purple) !important;
    background-color: rgba(0, 0, 0, 0.05) !important; /* Subtle hover effect */
}

.nav-links .active {
    color: var(--primary-purple) !important;
    font-weight: bold !important;
    border-bottom: 2px solid var(--primary-purple) !important; /* Highlight active link */
}

.nav-links button:focus {
    outline: none !important; /* Remove focus outline */
}

.header-scrolled .nav-links a, .header-scrolled .nav-links button {
    color: var(--dark-purple) !important;
}

.nav-links a:hover, .nav-links button:hover {
    color: var(--primary-purple) !important;
    background: none !important;
}

.active {
    color: var(--primary-purple) !important;
    font-weight: bold !important;
    /* background-color: rgba(0, 0, 0, 0.05) !important;  */
}

.active:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.right-section {
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex !important;
  align-items: center !important;
}

.login-button {
    color: white !important;
    background-color: var(--primary-purple) !important;
    font-size: 1.2rem !important;
    text-transform: capitalize !important;
    border-radius: 108px !important;
    padding: 10% 90% !important;
    margin-left: 10px !important;
}

.login-button:hover {
    background-color: var(--dark-purple) !important;
}

.menu-button {
    display: none !important;
}

.drawer, .MuiDrawer-paper {
    width: 50% !important;
    color: var(--deep-purple) !important;
}

.drawer .MuiListItemText-root {
    color: var(--dark-purple) !important;    
}

.menu {
    margin-top: 31.5px !important;
}

.menu-open::after {
    content: '' !important;
    display: block !important;
    width: 90% !important;
    height: 2px !important;
    background-color: var(--primary-purple) !important;
    position: absolute !important;
    bottom: -30px !important; /* Adjust if needed to align correctly */
    left: 3px !important;
}

.nav-links button[aria-controls="services-menu"] {
    position: relative !important;
}
  
.soon-badge {
    background-color: var(--primary-purple) !important;
    color: white !important;
    font-size: 0.75rem !important;
    font-weight: bold !important;
    padding: 2px 6px !important;
    border-radius: 12px !important;
    margin-left: 8px !important;
    display: inline-block !important;
    vertical-align: middle !important;
}
  
.menu .MuiMenuItem-root  {
    font-family: var(--font-title) !important;
}

.drawer .MuiListItemText-root:hover {
    color: var(--primary-purple) !important;
}

.MuiMenu-list {
    color: var(--dark-purple) !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    width: 220px !important;
}

.menu .MuiMenuItem-root {
    color: var(--dark-purple) !important;
    position: relative; /* Added */
}

.menu .MuiMenuItem-root:hover {
    color: var(--primary-purple) !important;
    background: none !important;
}
 
.menu-item {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
  }
  
  .menu-item.disabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }
  
  .menu-item .soon-badge {
    margin-left: auto !important;
  }

  .submenu {
    margin-left: 310.5px !important;
  }


  .logout-button {
    margin-left: auto !important;
    color: var(--dark-purple) !important;
}

.logout-button:hover {
    color: var(--primary-purple) !important;
}

.logout-tooltip {
    display: block !important;
}

.colored-icon {
    color: var(--primary-purple) !important;
    margin-right: 15px !important;
}

@media (max-width: 980px) {
    .nav-links {
        display: none !important;
    }

    .menu-button {
        display: block !important;
        margin-right: auto !important;
        color: var(--primary-purple) !important;
    }

    .logo {
        height: 45px !important;
    }
}

@media (max-width: 980px) {
    .MuiToolbar-root {
        justify-content: start !important;
    }
}


@media (max-width: 980px) {
    .login-button {
      font-size: 0.8rem !important;
      padding: 6px 12px !important;
      margin-left: 5px !important;
      margin-top: 10px !important;
      margin-right: 20px !important;
    }
  
    .right-section {
      flex-grow: 1 !important;
      justify-content: flex-end !important;
    }

    .menu {
        margin-top: 10px !important;
    }
}

@media (max-width: 980px) {
    .logout-button {
        display: none !important;
    }
}