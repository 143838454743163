.footer {
    background-color: var(--dark-purple) !important;
    /* background-color: var(--light-gray) !important; */
    padding: 20px 0 !important;
    color: var(--light-gray) !important;
    text-align: center !important;
  }
  
  .footer-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 20px !important; 
  }
  
  .footer-logo {
    height: 50px !important;
    background-color: white !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
  }
  
  .footer-text {
    color: var(--light-gray) !important;
    /* color: var(--dark-purple) !important; */
  }
  

  @media (max-width: 768px) {
    .footer-logo {
        display: none !important;
    }
      
  }
  