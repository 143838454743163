
:root {
  --primary-purple: #AD26BF !important;
  --dark-purple: #270373 !important;
  --deep-purple: #231459 !important;
  --mock-purple: #260373c6 !important;
  --sky-blue: #29A5F2 !important;
  --light-gray: #F2F2F2 !important;
  --gradient: linear-gradient(90deg, #270373, #231459, #29A5F2, #AD26BF) !important;
  --font-title: 'BuenosAires' !important;
  --font-body: 'BuenosAires' !important;
}


body {
  background-color: rgba(227, 227, 227, 0.263) !important;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--font-title) !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

body, h1, h2, h3, h4, h5, h6, .title {
  font-family: var(--font-title) !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: var(--font-title) !important;
}

.css-e784if-MuiTypography-root {
  font-family: var(--font-title) !important;

}

.announcement {
  font-family: var(--font-title) !important;
}

.regular-text {
  color: black !important;
}

.text-button {
  color: var(--primary-purple) !important;
  text-transform: capitalize !important;
}

.text-button:hover {
  color: var(--dark-purple) !important;
  background: none !important;
  text-decoration: underline !important;
}

.primary-button {
  background-color: var(--primary-purple) !important;
  color: var(--light-gray) !important;
  font-family: var(--font-title) !important;
  border-radius: 90px !important;
  /* max-width: 75% !important; */
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  font-size: 1.2rem !important;
  text-transform: capitalize !important;
}

.to-right-button {
  align-items: end !important;
  display: flex !important;
  margin-inline-start: auto !important;
}

.primary-button:hover {
  background-color: var(--dark-purple) !important;
}

.text-button {
  color: var(--primary-purple) !important;
}

.text-button:hover {
  color: var(--dark-purple) !important;
}

.slick-prev:before, .slick-next:before {
  display: none !important;
}

.Mui-focused {
  color: var(--dark-purple) !important;
}

@media (max-width: 992px) {
  .primary-button {
    font-size: 1rem !important;
  }

  .regular-text, .text-button{
    font-size: 1rem !important;
  } 

}

@media (max-width: 768px) {
  .primary-button {
    font-size: 1rem !important;
  }
  .regular-text, .text-button {
    font-size: .9rem !important;
  } 

}

@media (max-width: 576px) {
  .primary-button {
    font-size: 1rem !important;
  }

  .regular-text, .text-button {
    font-size: .7rem !important;
  } 
}

@media (max-width: 330px) {
  .primary-button {
    font-size: .8rem !important;
  }

  .regular-text, .text-button {
    font-size: .5rem !important;
  } 
}
